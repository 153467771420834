import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import tw, { styled } from "twin.macro"
import Helmet from "react-helmet"

const ImpressumContainer = styled.div`
  h1, h2, h3 {
    ${tw`font-bold`}
  }

  h1 {
    ${tw`pt-5 pb-3`}
  }

  h2 {
    ${tw`pt-5 pb-2`}
  }

  h3 {
    ${tw`pt-3`}
  }


`


const Impressum = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query ImpressumQuery {
          impressum: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/impressum/"}}) {
            edges {
              node {
                id
                frontmatter {
                  title
                }
                html
              }
            }
          }
        }
      `}
      render={data => (
        <Shell >
          <Helmet>
            <title>Impressum</title>
          </Helmet>
          <Container>
            <ImpressumContainer className='my-8'>
              <h1>Impressum</h1>
              <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: data.impressum.edges[0].node.html }} />
            </ImpressumContainer>
          </Container>
        </Shell>
      )}
    />
  )
}

export default Impressum

